import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { ExperimentsBag } from '@wix/wix-experiments';
import classNames from 'classnames';
import React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { EmptyState } from './components/EmptyState';
import styles from './Widget.scss';
import { onWindowResize } from './widgetResize';

interface WidgetProps {
  spotifyUri?: string;
  inEditor: boolean;
  width: number;
  height: number;
}

interface WidgetWrapperProps {
  appName: string;
  mobile: boolean;
  language: string;
  translations: Record<string, string>;
  experiments: ExperimentsBag;
  host: IHostProps;
  spotifyUri?: string;
  inEditor: boolean;
}

export default class extends React.Component<WidgetWrapperProps> {
  componentDidMount() {
    if (this.props.inEditor) {
      window.addEventListener('resize', onWindowResize);
    }
  }

  componentWillUnmount() {
    if (this.props.inEditor) {
      window.removeEventListener('resize', onWindowResize);
    }
  }

  render() {
    const { mobile, inEditor, spotifyUri } = this.props;
    const { width, height } = this.props.host.dimensions;

    return (
      <TPAComponentsProvider value={{ mobile }}>
        <Widget
          inEditor={inEditor}
          spotifyUri={spotifyUri}
          width={width}
          height={height}
        />
      </TPAComponentsProvider>
    );
  }
}

export const Widget = ({
  spotifyUri,
  inEditor,
  width,
  height,
}: WidgetProps) => {
  const version = window.__CI_APP_VERSION__;

  return (
    <div
      data-hook="spotify-wrapper"
      data-artifact-version={version}
      style={{ width, height }}
      className={classNames(styles.root, inEditor && styles.rootInEditor)}
    >
      {!spotifyUri ? (
        <EmptyState />
      ) : (
        <iframe
          className={styles.spotifyFrame}
          data-hook="spotify-iframe"
          src={
            'https://open.spotify.com/embed?uri=' +
            encodeURIComponent(spotifyUri)
          }
          frameBorder="0"
          // @ts-ignore because old react doesn't know correct attribute
          allowtransparency="true"
          allow="encrypted-media"
        />
      )}
    </div>
  );
};
