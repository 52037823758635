
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/components/Spotify/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://01fb0c955b864231aa35321b27af56a8@sentry.wixpress.com/440',
      id: '01fb0c955b864231aa35321b27af56a8',
      projectName: 'spotify',
      teamName: 'music',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'Spotify'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
